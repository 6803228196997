<template>
    <div>
        <fromSearch :object_='object_' @searchFun='getList'/>
        <avue-crud :option="tableOption" :data="page.list" :page="page"
            :table-loading="listLoading"
            @size-change="sizeChange"
            @current-change="currentChange">
            <template #menu-left>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"  @click="openView(0)">{{$t("button.add")}}</el-button>
                <el-button v-if="roleManager_btn_add" class="filter-item"
                    type="primary"  @click="importFun">{{$t("button.imports")}} </el-button>
                <el-button v-if="roleManager_btn_import" class="filter-item"
                    type="primary"  @click="imports_">{{$t("button.imports1")}}</el-button>
            </template>
            <template #status ="scope">
                <el-switch v-model="scope.row.status" :inactive-value='valueArray[0].value' :active-value='valueArray[1].value' @change='enableFun(scope.row)'/>
            </template>
            <template #caozuo ="scope">
                <el-button v-if="roleManager_btn_edit" text type="primary"
                    size="small"   @click="openView(1,scope.row)">{{$t("button.update")}} </el-button>
                <el-button v-if="roleManager_btn_del" text type="primary" size="small"
                     @click="rowDel(scope.row)">{{$t("button.del")}} </el-button>
            </template>
            <template #createTime="scope">
                {{turnTimeFun(scope.row.createTime)}}
            </template>
            <template #updateTime="scope">
                {{turnTimeFun(scope.row.updateTime)}}
            </template>
            <template #msgType="scope">
                <el-tag v-for='item in getOBG(scope.row.msgType)' :key='item'>{{  getDataAboutText(7,item)}}</el-tag>
            </template>
            <template #templateCode="scope">
                {{  getDataAboutText(6,scope.row.templateCode)}}
            </template>
        </avue-crud>
        
        <excel-upload
            ref="excelUpload_"
            :title="$t('importss.text1')"
            url="/message/syssmstemplate/import"
            temp-name=".xlsx"
            temp-url="/message_template.xlsx"
            @refreshDataList="getList"
        ></excel-upload>
        <el-dialog v-model="dialogFormVisible" :title='isStyleText[isStyle]' width="600">
            <bindView ref='bindView_' @successFun='successFun' @closeFun='dialogFormVisible = false'/>
        </el-dialog>

    </div>
</template>
<script setup>
    import Api_ from '@/api/index'
    import { ref,nextTick,getCurrentInstance } from 'vue'
    import { tableOption1 } from "@/const/crud/messTemplate";
    import { ElNotification } from 'element-plus'
    import { downBlobFile } from '@/utils/util'
    import fromSearch from '@/components/fromSearch.vue'
    import { object } from '@/const/from/admin/smsTem'
    import excelUpload from '@/components/upload/excel.vue'
    import bindView from './bind.vue'
    const { $t } = getCurrentInstance().proxy;  
    import mixins from '@/views/mixins/page'
    import { turnTimeFun } from '@/utils/util.js'
    import { getDataAboutText,getDataStyle } from '@/utils/dataBind/parmsView'  
    import { parmsArray } from '@/config/data'

    let pageObj = mixins(['getMessTemplateList',['detalMessTemplateList','templateId']])
    const {page,dialogFormVisible,listLoading,sizeChange,currentChange,getList,rowDel,successFun} = pageObj
    const isStyle = ref(0)
    const isStyleText = [$t("button.add"),$t("button.update")]
    const valueArray = ref([])
    getDataStyle(parmsArray[6],()=>{})
    getDataStyle(parmsArray[4],(e)=>{valueArray.value = e})
    
    const object_ = ref(object);
    const roleManager_btn_add = ref(true)
    const tableOption = ref({});tableOption.value = tableOption1
    // const roleManager_btn_update = ref(true)
    const roleManager_btn_edit = ref(true)
    const roleManager_btn_del = ref(true)
    const roleManager_btn_import = ref(true)
    const excelUpload_ = ref(null)
    const bindView_ = ref(null)

    const getOBG = (item)=>{
        if(item){
            return item.split(',')
        }
    }
    const imports_ = ()=>{
        excelUpload_.value.show()
    }
    const importFun = ()=>{//导出
        downBlobFile('/message/syssmstemplate/export',$t('downName.name_1')+".xlsx")
    }
    const enableFun = (e)=>{
        if(e.templateId){
            Api_.upateMessTemplateList({status:e.status,templateId:e.templateId}).then(res=>{
                listLoading.value = false
                if(res.code === 0){
                    ElNotification({
                        message: e.status === '1'?$t('alert.succOpen'):$t('alert.succOff'),
                        type: 'success',
                    })
                    getList()
                }else{
                    ElNotification({
                        message: res.msg,
                        type: 'error',
                    })
                }
            })
        }
    }
    const openView = (num,parm)=>{
        isStyle.value = num
        dialogFormVisible.value = true
        nextTick(()=>{
            bindView_.value.init_(num,parm)
        })
    }
    getList(1)
</script>