import il8n from '@/assets/language/index.js';
const { t } = il8n.global;
import { parmsArray } from '@/config/data'

export const object = [{
    type: 'input',
    name:  t("adminMessT.name3"),
    prop: 'content',
},{
    type: 'select',
    name: t("adminMessT.name5"),
    prop: 'msgType',
    isParms: parmsArray[7]
},{
    type: 'datetime',
    name: t("adminMessT.name6"),
    prop: 'createTime'
}]