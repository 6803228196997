import il8n from '@/assets/language/index.js';
const { t } = il8n.global;

export const tableOption1 = {
        border: true,
        index: true,
        indexLabel: t("adminMessT.name1"),
        indexWidth: 80,
        height: 'auto',
        stripe: true,
        menuAlign: 'center',
        searchMenuSpan: 6,
        editBtn: false,
        delBtn: false,
        align: 'center',
        addBtn: false,
        viewBtn: false,
        refreshBtn: false,
        columnBtn: false,
        menu:false,
        column: [{
            width: 160,
            label: t("adminMessT.name2"),
            prop: 'language',
            span: 24,
            type: 'select',
            props: {
                label: "label",
                value: "value"
            },
            dicData: [{
                label: t("adminMessT.name2_1"),
                value: 'zh'
            }, 
             {
                label: t("adminMessT.name2_2"),
                value: 'en'
            }, {
                label: t("adminMessT.name2_3"),
                value: 'zh_hk'
            }],
        }, {
            width: 200,
            label: t("adminMessT.name3"),
            prop: 'content',
        }, {
            width: 150,
            label: t("adminMessT.name4"),
            prop: 'templateCode',
        }, {
            width: 150,
            label: t("adminMessT.name5"),
            prop: 'msgType',
        }, 
        {
            width: 170,
            label:t("adminMessT.name6"),
            prop: 'createTime',
            type: 'datetime',
        }, 
        {
            width: 170,
            label: t("adminMessT.name7"),
            prop: 'updateTime',
            type: 'datetime',
        },{
            width: 120,
            label: t("adminMessT.name8"),
            prop: 'updateUser',
        },{
            label: t("adminMessT.name9"),
            prop: 'status',
            width: 120,
            fixed:'right',
        },{
            width: 140,
            label: t("button.operation"),
            prop: 'caozuo',
            placeholder: t("inputTit.shuru"),
            fixed:'right',
          },]
    }