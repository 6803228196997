<template>
  <div>
    <!-- 导入对话框 -->
    <el-dialog
      :title="title"
      v-model="upload.open"
      width="400px"
      append-to-body
    >
      <el-upload
        ref="upload_"
        :limit="1"
        accept=".xlsx, .xls"
        :headers="headers()"
        :action="url"
        :disabled="upload.isUploading"
        :on-progress="handleFileUploadProgress"
        :on-success="handleFileSuccess"
        :on-error="handleFileError"
        :auto-upload="false"
        drag
      >
        <i class="el-icon-upload"></i>
        <div class="el-upload__text">
          {{$t("importss.text2")}}
          <em>{{$t("importss.text3")}}</em>
        </div>
        <template #tip>
            <div class="el-upload__tip text-center">
          <span>{{$t("importss.text4")}}</span>
          <el-link
            type="primary"
            :underline="false"
            style="font-size:12px;vertical-align: baseline;"
            @click="downExcelTemp"
            v-if="tempUrl"
          >{{$t("importss.text5")}}
          </el-link>
        </div>
        </template>
        
      </el-upload>
      <template #footer>
        
      <div class="dialog-footer">
        <el-button type="primary" @click="submitFileForm">{{$t("button.enter")}}</el-button>
        <el-button @click="upload.open = false">{{$t("button.close")}}</el-button>
      </div>
      </template>
    </el-dialog>

    <!--校验失败错误数据-->
    <el-dialog title="校验失败数据" v-model="errorVisible">
      <el-table :data="errorData">
        <el-table-column
          property="lineNum"
          label="行号"
          width="50"
        ></el-table-column>
        <el-table-column
          property="errors"
          label="错误描述"
          show-overflow-tooltip
        >
          <template #="scope">
            <el-tag
              type="danger"
              v-for="error in scope.row.errors"
              :key="error"
            >{{ error }}
            </el-tag
            >
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
  </div>
</template>

<script setup>
    import { ref,reactive,defineProps,defineEmits,defineExpose } from 'vue'
    import { questStore } from '@/store/quest'
    import { downBlobFileTemplate } from '@/utils/util'
    import { ElNotification } from 'element-plus'
    import staticAddress from '@/config/website'

    const props = defineProps({
        url: String,
        title: String,
        tempUrl: String
    })
    const upload = reactive({
        open: false,
        isUploading: false
    })
    const upload_ = ref(null)
    const errorVisible = ref(false)
    const errorData = ref([])
    const emit = defineEmits(['refreshDataList'])

    const url = ref('')
    const title = ref('')
    const tempUrl = ref('')


    const headers = ()=>{
        const tenantId = questStore().getTenantId
        return {
            'Authorization': "Bearer " + questStore().getToken,
            'TENANT-ID': tenantId ? tenantId : '1'
        };
    }
    const downExcelTemp = ()=>{
        downBlobFileTemplate(staticAddress.staticAddress + tempUrl.value,)
    }
    const handleFileUploadProgress = ()=>{
        upload.isUploading = true;
    }
    const handleFileError = ()=>{
        ElNotification({
            message: '上传失败,数据格式不合法!',
            type: 'error',
        })
        upload.open = false;
    }
    const handleFileSuccess = (response)=>{
        upload.isUploading = false;
        upload.open = false;
        upload_.value.clearFiles();
         // 校验失败
        if (response.code === 1) {
            ElNotification({
                message: '导入失败，以下数据不合法',
                type: 'error',
            })
            errorVisible.value = true;
            errorData.value = response.data;
            upload_.value.clearFiles();
        } else {
            
            ElNotification({
                message: response.msg ? response.msg : "导入成功",
                type: 'success',
            })
            emit("refreshDataList");
        }
    }
    const submitFileForm = ()=>{
        upload_.value.submit();
    }
    const show = ()=>{
        let url_ = sessionStorage.getItem('baseURL') || 'http://api.waastest.cloud:20801' 
        // let url_ = sessionStorage.getItem('baseURL') || 'http://192.168.3.236:9999' 
        upload.isUploading = false;
        upload.open = true;
        url.value = url_ + props.url
        title.value = props.title
        tempUrl.value = props.tempUrl
    }
    defineExpose({show})
</script>
